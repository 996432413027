<template>
  <button
    :class="[
      { 'bg-brand-greyblack-300/50 backdrop-blur-sm': isDark },
      { 'hover:bg-white/[0.15] active:bg-white/30': enableHover },
      { 'border-2 border-white/50': outlined },
      { 'hover:scale-110 active:scale-95': scaleUpOnHover },
    ]"
    ref="trigger"
    class="group h-9 w-9 lg:h-12 lg:w-12 flex justify-center items-center rounded-full outline-none duration-100 transition-all disabled:cursor-not-allowed"
    @click="onClick"
  >
    <client-only>
      <Icon
        :name="iconName"
        :size="parsedSize"
        :color="color"
        :class="iconClass"
        class="hidden lg:block"
      ></Icon>
    </client-only>
  </button>
</template>

<script setup>
const { iconName, size, color, breakpoints } = defineProps({
  iconName: {
    required: true,
    type: String,
  },

  breakpoints: Object,
  color: {
    type: String,
    default: () => null,
  },
  iconClass: String,
  isDark: {
    type: Boolean,
    default: () => false,
  },
  enableHover: {
    type: Boolean,
    default: () => true,
  },
  outlined: {
    type: Boolean,
    default: () => false,
  },
  scaleUpOnHover: {
    type: Boolean,
    default: () => true,
  },
});

const emit = defineEmits("click");
const { breakpoints: bp } = useBreakpoints();

const trigger = ref();

const parsedSize = ref(20);

onMounted(() => {
  if (breakpoints) {
    if (bp.greaterOrEqual("lg").value) {
      parsedSize.value = breakpoints.lg;
    } else if (bp.greaterOrEqual("md").value) {
      parsedSize.value = breakpoints.md;
    } else {
      parsedSize.value = breakpoints.sm;
    }

    return;
  }

  if (bp.greaterOrEqual("lg")) {
    parsedSize.value = "24";
  } else {
    parsedSize.value = "20";
  }
});

const onClick = () => trigger.value?.blur();
</script>

<script>
export default { name: "ButtonsIcon" };
</script>
